import { SVGWrapper } from '../lib/svg';
import { DisorderlyTheme } from '../theme/theme';

export const createLogoLettermarkSVG = (): SVGWrapper => {
  const BAR_COLORS = [
    ...Object.values(DisorderlyTheme.colors.core).slice(0, 6),
  ];
  const viewBoxHeight = 100;
  const viewBoxWidth = 89;
  const barX = 0;
  const barGap = viewBoxHeight / 20;
  const barHeight = (viewBoxHeight - barGap * 5) / 6;
  const barWidth = barHeight * 2.5;

  const barCurveGap = 7;

  const curveWidth = 18;
  const curveX = barX + barCurveGap + barWidth;
  const curveY1 = curveWidth / 2;
  const curveY2 = viewBoxHeight - curveY1;
  const curveRadius = 10;

  const curvePath = [
    `M${curveX},${curveY1}`,
    `A${curveRadius},${curveRadius} 0 0,1 ${curveX},${curveY2}`,
  ];
  const curve = `<path d="${curvePath.join(' ')}" stroke="#FFFFFF" stroke-width="${curveWidth}" fill="none" />`;

  const bars = BAR_COLORS.map((color, index) => {
    const y = index * barHeight + barGap * index;
    return `<rect  x="${barX}" y="0" width="${barWidth}" transform="translate(0 ${y})" height="${barHeight}" fill="${color}"/>`;
  });

  return new SVGWrapper()
    .viewBox(`0 0 ${viewBoxWidth} ${viewBoxHeight}`)
    .elements([...bars, curve]);
};

export const createLogoWordmarkSVG = (): SVGWrapper => {
  const viewBoxHeight = 120;
  const viewBoxWidth = 610;

  const lettermarkSvg = createLogoLettermarkSVG()
    .preserveAspectRatio('xMinYMin')
    .height(100);
  const isordelryText = `
      <text
        x="95" y="80"
        fill="white"
        font-family="helvetica"
        font-size="90px"
        font-weight="700"
      >ISORDERLY</text>`;
  const urlText = `
      <text
        x="600" y="110"
        fill="white"
        font-family="helvetica"
        font-size="32px"
        font-weight="300"
        letter-spacing="1.3px"
        text-anchor="end"
        opacity="0.7"
      >playdisorderly.com</text>`;
  return new SVGWrapper()
    .viewBox(`0 0 ${viewBoxWidth} ${viewBoxHeight}`)
    .elements([lettermarkSvg.toString(), isordelryText, urlText]);
};
