export class SVGWrapper {
  private _elements: string[] = [];
  private _x?: number;
  private _y?: number;
  private _viewBox?: string;
  private _preserveAspectRatio?: string;
  private _height?: number;

  viewBox(_viewBox: string) {
    this._viewBox = _viewBox;
    return this;
  }

  getViewBox() {
    return this._viewBox;
  }

  elements(elements: string[]) {
    this._elements = elements;
    return this;
  }

  height(height: number) {
    this._height = height;
    return this;
  }

  x(x: number) {
    this._x = x;
    return this;
  }

  y(y: number) {
    this._y = y;
    return this;
  }

  preserveAspectRatio(preserveAspectRatio: string) {
    this._preserveAspectRatio = preserveAspectRatio;
    return this;
  }

  toString(): string {
    const attrs = [
      `viewBox="${this._viewBox}"`,
      this._x ? `x="${this._x}"` : '',
      this._y ? `y="${this._y}"` : '',
      this._height ? `height="${this._height}"` : '',
      this._preserveAspectRatio
        ? `preserveAspectRatio="${this._preserveAspectRatio}"`
        : '',
    ];
    return `
    <svg xmlns="http://www.w3.org/2000/svg" ${attrs.join(' ')}>
      ${this._elements.join('\n')}
    </svg>
    `;
  }
}
