import React, { type MouseEvent } from 'react';

import { createLogoWordmarkSVG } from '../../../logo/logo';
import { shuffleArray } from '../../lib/array';

const logo = createLogoWordmarkSVG();

const animationStrategy = {
  swap: (rects: SVGRectElement[]): Record<number, string> => {
    const indexes = [0, 1, 2, 3, 4, 5];
    const [first, second] = shuffleArray(indexes);
    const [rect1, rect2] = [rects[first], rects[second]];
    const rect1Transform = rect1.getAttribute('transform');
    const rect2Transform = rect2.getAttribute('transform');
    return {
      [first]: rect2Transform ?? '',
      [second]: rect1Transform ?? '',
    };
  },
  shift: (rects: SVGRectElement[]): Record<number, string> => {
    const rectsInOrder = [...rects]
      .map((rect, index) => ({ rect, originalIndex: index }))
      .sort((a, b) => {
        return (
          a.rect
            .getAttribute('transform')
            ?.localeCompare(b.rect.getAttribute('transform') ?? '') ?? 0
        );
      });

    const indexes = [0, 1, 2, 3, 4, 5];
    const [indexMoving, indexMovingTo] = shuffleArray(indexes);
    const ret: Record<number, string> = {};
    if (indexMoving < indexMovingTo) {
      for (let i = indexMoving + 1; i <= indexMovingTo; i += 1) {
        ret[rectsInOrder[i].originalIndex] =
          rectsInOrder[i - 1].rect.getAttribute('transform') ?? '';
      }
    } else {
      for (let i = indexMoving - 1; i >= indexMovingTo; i -= 1) {
        ret[rectsInOrder[i].originalIndex] =
          rectsInOrder[i + 1].rect.getAttribute('transform') ?? '';
      }
    }
    ret[rectsInOrder[indexMoving].originalIndex] =
      rectsInOrder[indexMovingTo].rect.getAttribute('transform') ?? '';
    return ret;
  },
};
export const HeaderLogo = () => {
  const svgRef = React.useRef<HTMLDivElement>(null);
  const animationIntervalRef = React.useRef<any>(null);

  const handleMouseEnter = React.useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const doSwap = () => {
        const rects = svgRef.current?.querySelectorAll('rect');
        if (!rects) {
          return;
        }
        const transformUpdates = animationStrategy.shift([...rects]);
        rects.forEach((rect, index) => {
          rect.setAttribute(
            'transform',
            transformUpdates[index] ?? rect.getAttribute('transform')
          );
        });
      };
      console.log('here');
      clearInterval(animationIntervalRef.current);
      doSwap();
      animationIntervalRef.current = setInterval(doSwap, 1500);
    },
    [animationIntervalRef.current]
  );

  const handleMouseLeave = React.useCallback(() => {
    clearInterval(animationIntervalRef.current);
  }, [animationIntervalRef.current]);

  return (
    <div
      className="flex items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={svgRef}
        className="logo inline-block w-44 sm:w-56"
        dangerouslySetInnerHTML={{ __html: logo.toString() }}
      ></div>
    </div>
  );
};
